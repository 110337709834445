body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-header {
  background-color: rgba(250, 202, 43, 0.05) !important;
}

.card-footer {
  background-color: inherit !important;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-primary {
  font-weight: bold !important;
  border-radius: 10px;
}

.btn-danger {
  font-weight: bold !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:disabled {
  background-color: #faca2b !important;
  border-color: #faca2b;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:disabled {
  background-color: #f4675b !important;
  border-color: #f4675b !important;
}

.btn-primary:focus {
  box-shadow: none !important;
  border-color: #faca2b !important;
}

.btn-danger:focus {
  box-shadow: none !important;
  border-color: #f4675b !important;
}

.bg-danger {
  background-color: #f4675b !important;
}

.text-primary {
  color: #f4675b !important;
}

.footer-list {
  list-style-type: none;
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-link:hover {
  color: #f4675b;
}

.border-secondary {
  border-color: #faca2b !important;
  border-radius: 15px !important;
}
